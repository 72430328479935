.lpf-40 {
    line-height: 40px;
    padding-top: 22px;
    font-size: 30px;
}

.loginCheck {
    color: #fff;
    line-height: 22px;
    padding-bottom: 11px;
    padding-top: 5px;
}

.brLogin-4 {
    border-radius: 8px 0px 0px 8px;
}

.brLoginNew-4 {
    border-radius: 0px 8px 8px 0px;
}

.ptNew-19 {
    padding-top: 19px;
}

.ptNew-9 {
    padding-top: 9px;
}
.ptNew-18 {
    padding-top: 18px;
}

.pxNew-8 {
    padding-top: 2px;
    padding-left: 27px;
    padding-right: 40px;
}

.ptNew-10 {
    padding-top: 10px;
}

.ptNew-0 {
    padding-top: 14px;
    padding-bottom: 0px;
}

.ptNew-18 {
    padding-top: 18px;
}

@keyframes heightAnimate {
    0% {
        top: 90px;
        opacity: 0;
    }
    100% {
        top: 0px;
        opacity: 1;
    }
}

.login-banner {
    .awssld {
        height: 362px !important;
    }
    .awssld__wrapper {
        height: 445px !important;
    }
    .awssld__bullets .awssld__bullets--active {
        width: 6px !important;
        height: 6px !important;
        background: #ff4f17 !important;
        border-radius: 30px !important;
        transform: none !important;
    }
}


.awssld {
    --organic-arrow-thickness: 3px;
    --organic-arrow-height: 8px;
    --slider-height-percentage: 60%;
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-opacity: 0.5;
    --control-button-hover-opacity: 0.75;
    --control-button-background: transparent;
    --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
    --slider-transition-duration: 575ms;
    --organic-arrow-color: #6a6a6a;
    --organic-arrow-border-radius: 0;
    --control-bullet-color: #6a6a6a;
    --content-background-color: #6a6a6a;
}
.awssld__bullets {
    bottom: -1px !important;
    z-index: 9;
}
.awssld__controls {
    position: relative;
    top: 39px;
    // margin-right: calc(50% - 24%);
    margin-left: var(--arrow-offer-value);
    margin-right: var(--arrow-offer-value);
    // margin-left: 28px;
    // margin-right: 28px;
}
.awssld {
    --organic-arrow-thickness: 3px !important;
    --organic-arrow-height: 8px !important;
    --loader-bar-height: 6px !important;
    --control-button-height: 8% !important;
    --control-bullet-color: #ccc !important;
    --control-bullet-active-color: #333 !important;
    height: 200px !important;
}
.awssld__bullets button {
    width: 6px !important;
    height: 6px !important;
    background: #dbdbdb !important;
    border-radius: 30px !important;
}
.awssld__bullets .awssld__bullets--active {
    width: 12px !important;
    height: 6px !important;
    background: #b3b3b3 !important;
    border-radius: 30px !important;
}

.carousalImg {
    .awssld__bullets button {
        width: 6px !important;
        height: 6px !important;
        background: rgba(255, 255, 255, 0.7) !important;
        border-radius: 30px !important;
    }
    .awssld__bullets .awssld__bullets--active {
        width: 12px !important;
        height: 6px !important;
        background: #ffffff !important;
        border-radius: 30px !important;
    }
    .awssld__bullets {
        bottom: 16px !important;
        z-index: 9;
    }
    .awssld__controls {
        position: relative;
        top: 20px;
        margin-left: var(--arrow-value);
        margin-right: var(--arrow-value);
        // margin-right: calc(50% - 24%);
        // margin-left: calc(48 - (1 * var(--arrow-value)));
        // margin-right: calc(50 - (1 * var(--arrow-value)));
    }
    .slick-dots {
        li:before {
            background-color: #b3b3b3;
        }
        li.slick-active:before {
            width: 6px;
            background-color: #ff4f17;
        }
    }
}
.awssld__timer{
    opacity: 0;
    pointer-events: none;
}
.bannerAnimation {
    animation: heightAnimate 0.4s linear;
}

.h-100 {
    height: 100%;
}

.banner-slider-image{
    margin-top: -54px;
    width: 350px;
}

