.dropdown {
    &__item {
        &:hover {
            .hover__item {
                color: white;
            }
        }
    }
    &__selected {
        * {
            color: white;
        }
    }
}
