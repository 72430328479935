@import '~@cleartrip/bento/bento.css';

@import './normalize.scss';
@import './login.scss';

html {
    font-family: sans-serif;
}

@supports (font-variation-settings: normal) {
    html {
        font-family: sans-serif;
    }
}

.App {
    width: 100%;
    height: 100%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font: caption;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, Arial, sans-serif;
}

html {
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'WebRupee';
    src: url('../fonts/WebRupee.V2.0.eot');
    src: url('../fonts/WebRupee.V2.0.eot?#iefix') format('embedded-opentype');
    src: local('WebRupee'), url('../fonts/WebRupee.V2.0.ttf') format('truetype'),
        url('../fonts/WebRupee.V2.0.woff') format('woff'), url('../fonts/WebRupee.V2.0.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

.inr {
    font-family: 'WebRupee';
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate-270 {
    transform: rotate(270deg);
}

.flip {
    transform: scaleX(-1);
}

.h_40p {
    height: 40px;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.arrow_rtl {
    left: 16px;
    right: unset;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.npx-0 {
    padding-left: 0px;
    padding-right: 0px;
}

.btr-8 {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.bbr-8 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.fpl-4 {
    padding-left: 4px !important;
}

.popup-container {
    visibility: hidden;
    opacity: 0;
    // transition: all 0.3s ease-in-out;
    // transform: scale(1.3);
    transition-timing-function: ease-in;

    /* Quick on the way out */
    transition: 0.2s;

    /* Hide thing by pushing it outside by default */
    //130% bottom to top
    transform: translateX(-150%);
    -webkit-transform: translateX(-50%);

    &.show {
        visibility: visible;
        opacity: 1;
        transition-timing-function: ease-out;

        /* A litttttle slower on the way in */
        transition: 0.25s;

        /* Move into place */
        transform: translateX(0);
        //transform: scale(1);
    }
}

.br-8 {
    border-radius: 8px;
}

.br-6 {
    border-radius: 6px;
}

.hbs {
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.04);
}

.h-48p {
    min-height: 48px;
}

.fs-14 {
    font-size: 14px;
    line-height: 24px;
}

.br-12 {
    border-radius: 12px;
}
.br-32 {
    border-radius: 32px;
}
.w-272 {
    width : 272px;
}

.ml-150 {
 margin-left: 150px;
}
.bg-axis-translucent {
    background: rgba(243, 243, 243, 0.1);
}

.mr-48 {
    margin-right: 48px;
}

.t-48 {
    top: 48px;
}
.lh-14 {
    line-height: 14px;
}
.lh-16 {
    line-height: 16px;
}
.lh-22 {
    line-height: 22px;
}
.lh-24 {
    line-height: 24px;
}

.c-green-500 {
    color: #11a670;
}

.listItemHover:hover {
    background-color: #eff5fc;
    border-radius: 6px;

    a {
        color: #3366cc;
    }

    .stroke {
        stroke: #3366cc;
    }

    .fill {
        fill: #3366cc;
    }
}

.c-neutral-white {
    color: #fff;
}

.c-neutral-grey {
    color: #666666;
}
.bg-intlnew-orange {
    background-color: #ED6521;
}
.c-grey{
    color: #808080;
}
@media only screen and (max-width: 1155px) {
    .mr-48 {
        margin-right: 0px;
    }

    .homeCalender button {
        min-width: 170px !important;
    }
}

.disableTextSplFare {
    color: #b3b3b3 !important;
}


.inlineAnimation {
    transition: all 0.5s ease-in-out;
}

.h-141{
height: 141px;
}

.h-19{
    height: 76px;
    }
.h-16{
    height: 64px;
    }
.h-0p {
    height: 0px;
}

.h-25{
    height: 100px;
}

.w-447 {
    width: 447px;
}

.bg-light-orange {
    background-color: #FFF1EC;
}
.stopScrolling {
    height: 100%;
    overflow: hidden;
}

.sortOption{
    background-color: white;
}

.sortOption:hover {
    background-color: #F3F3F3;
}

.slidefromright {
    transition: transform 3s;
    transform: translateX(100%)
}

.tippy-tooltip.veryDark2-theme {
    background-color: black !important;
    color: white;
}

div.tippy-tooltip.veryDark2-theme div.arrow-small {
    border-bottom-color: black !important;
    border-top-color: black !important;
    left: auto !important;
}

div.tippy-tooltip.veryDark2-theme div.arrow-regular {
    border-bottom-color: black !important;
    border-top-color: black !important;
    left: auto !important;
}

div.tippy-tooltip.veryDark2-theme div.arrow-big {
    border-bottom-color: black !important;
    border-top-color: black !important;
    left: auto !important;
}

.bg-Axis-Gradient {
    background: #1a1a1a !important; /* Fallback color */
    background: linear-gradient(360deg, #1a1a1a 66.88%, rgba(26, 26, 26, 0) 100%),
    linear-gradient(78.49deg, rgba(174, 40, 93, 0) 16.92%, rgba(174, 40, 93, 0.5) 100%),
    linear-gradient(78.49deg, rgba(255, 79, 23, 0.3) 16.92%, rgba(255, 79, 23, 0) 100%), #1a1a1a !important;
}
.emiScrollableText {
    --spacingdx: 0px;
    animation: scrollText 7s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}
  
@keyframes scrollText {
    0% {
        transform: translateX(0%);
    }
    25% {
        transform: translateX(var(--spacingdx));
    }
    50% {
        transform: translateX(var(--spacingdx));
    }
    75% {
        transform: translateX(0%);
    }
}

.asm-grid {
    &__container {
        display: grid;
        grid-template-rows: 76px 0px;
    }
    &__col {
        &--container {
            display: grid;
            grid-template-columns: 64px 525px 200px;
        }
    }
}

.multipax-selected {
    &__grid {
        display: grid;
        grid-template-columns: 94px 104px 1fr;
        align-items: center;
    }
}
.emiBankList {
    background-color: #FFFFFF;
    &:hover {
        background-color: #F5F9FF;
    }
}

.btn-width {
    width: 185px;
}
